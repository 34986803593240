import clsx from "clsx";
import { PropsWithChildren } from "react";

export function Container({
  className,
  ...props
}: PropsWithChildren<{ className?: string; id?: string }>) {
  return (
    <div className={clsx("mx-auto max-w-7xl px-2", className)} {...props} />
  );
}
